/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:274e8eb8-43ab-45d2-b271-6df40cad6152",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_SjYJwnfsV",
    "aws_user_pools_web_client_id": "37g7o6ddhc64too9vu5jjhq6nf",
    "oauth": {},
    "aws_cognito_login_mechanisms": [
        "EMAIL",
        "PREFERRED_USERNAME",
        "PHONE_NUMBER"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_appsync_graphqlEndpoint": "https://pazelwqki5ewfkfns77fb6t7ym.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://ax0gwwmk14.execute-api.eu-west-1.amazonaws.com/staging",
            "region": "eu-west-1"
        }
    ],
    "aws_user_files_s3_bucket": "cbc-videos162945-staging",
    "aws_user_files_s3_bucket_region": "eu-west-1"
};


export default awsmobile;
