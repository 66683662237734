/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import {
  TopToolbar, CreateButton, RefreshButton,
  EditButton, List, Datagrid, TextField, BooleanField,
} from 'react-admin';
import { PropTypes } from 'prop-types';
import Check from '@material-ui/icons/Check';
import Clear from '@material-ui/icons/Clear';

const CareerActions = ({ basePath }) => (
  <TopToolbar>
    <CreateButton basePath={basePath} />
    <RefreshButton />
  </TopToolbar>
);

CareerActions.propTypes = {
  basePath: PropTypes.string.isRequired,
};

const CareerList = (props) => (
  <List
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
    actions={<CareerActions />}
    bulkActionButtons={false}
  >
    <Datagrid rowClick="edit">
      <TextField source="name" />
      <TextField source="category.name" label="Category" />
      <TextField source="short_description" />
      <BooleanField source="archive" label="Active" TrueIcon={Clear} FalseIcon={Check} valueLabelTrue="This career is archived" valueLabelFalse="This career is active" />
      <EditButton />
    </Datagrid>
  </List>
);

export default CareerList;
