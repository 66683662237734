/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createCategory = /* GraphQL */ `
  mutation CreateCategory(
    $input: CreateCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    createCategory(input: $input, condition: $condition) {
      id
      name
      colour
      icon
      careers {
        nextToken
      }
      questions {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateCategory = /* GraphQL */ `
  mutation UpdateCategory(
    $input: UpdateCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    updateCategory(input: $input, condition: $condition) {
      id
      name
      colour
      icon
      careers {
        nextToken
      }
      questions {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteCategory = /* GraphQL */ `
  mutation DeleteCategory(
    $input: DeleteCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    deleteCategory(input: $input, condition: $condition) {
      id
      name
      colour
      icon
      careers {
        nextToken
      }
      questions {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createCareer = /* GraphQL */ `
  mutation CreateCareer(
    $input: CreateCareerInput!
    $condition: ModelCareerConditionInput
  ) {
    createCareer(input: $input, condition: $condition) {
      id
      name
      short_description
      description
      categoryID
      archive
      category {
        id
        name
        colour
        icon
        createdAt
        updatedAt
      }
      relatedCareers {
        nextToken
      }
      videos {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateCareer = /* GraphQL */ `
  mutation UpdateCareer(
    $input: UpdateCareerInput!
    $condition: ModelCareerConditionInput
  ) {
    updateCareer(input: $input, condition: $condition) {
      id
      name
      short_description
      description
      categoryID
      archive
      category {
        id
        name
        colour
        icon
        createdAt
        updatedAt
      }
      relatedCareers {
        nextToken
      }
      videos {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteCareer = /* GraphQL */ `
  mutation DeleteCareer(
    $input: DeleteCareerInput!
    $condition: ModelCareerConditionInput
  ) {
    deleteCareer(input: $input, condition: $condition) {
      id
      name
      short_description
      description
      categoryID
      archive
      category {
        id
        name
        colour
        icon
        createdAt
        updatedAt
      }
      relatedCareers {
        nextToken
      }
      videos {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createQuestion = /* GraphQL */ `
  mutation CreateQuestion(
    $input: CreateQuestionInput!
    $condition: ModelQuestionConditionInput
  ) {
    createQuestion(input: $input, condition: $condition) {
      id
      description
      categoryID
      category {
        id
        name
        colour
        icon
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateQuestion = /* GraphQL */ `
  mutation UpdateQuestion(
    $input: UpdateQuestionInput!
    $condition: ModelQuestionConditionInput
  ) {
    updateQuestion(input: $input, condition: $condition) {
      id
      description
      categoryID
      category {
        id
        name
        colour
        icon
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteQuestion = /* GraphQL */ `
  mutation DeleteQuestion(
    $input: DeleteQuestionInput!
    $condition: ModelQuestionConditionInput
  ) {
    deleteQuestion(input: $input, condition: $condition) {
      id
      description
      categoryID
      category {
        id
        name
        colour
        icon
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createRelatedCareersBridge = /* GraphQL */ `
  mutation CreateRelatedCareersBridge(
    $input: CreateRelatedCareersBridgeInput!
    $condition: ModelRelatedCareersBridgeConditionInput
  ) {
    createRelatedCareersBridge(input: $input, condition: $condition) {
      id
      careerID
      relatedCareerID
      career {
        id
        name
        short_description
        description
        categoryID
        archive
        createdAt
        updatedAt
      }
      relatedCareer {
        id
        name
        short_description
        description
        categoryID
        archive
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateRelatedCareersBridge = /* GraphQL */ `
  mutation UpdateRelatedCareersBridge(
    $input: UpdateRelatedCareersBridgeInput!
    $condition: ModelRelatedCareersBridgeConditionInput
  ) {
    updateRelatedCareersBridge(input: $input, condition: $condition) {
      id
      careerID
      relatedCareerID
      career {
        id
        name
        short_description
        description
        categoryID
        archive
        createdAt
        updatedAt
      }
      relatedCareer {
        id
        name
        short_description
        description
        categoryID
        archive
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteRelatedCareersBridge = /* GraphQL */ `
  mutation DeleteRelatedCareersBridge(
    $input: DeleteRelatedCareersBridgeInput!
    $condition: ModelRelatedCareersBridgeConditionInput
  ) {
    deleteRelatedCareersBridge(input: $input, condition: $condition) {
      id
      careerID
      relatedCareerID
      career {
        id
        name
        short_description
        description
        categoryID
        archive
        createdAt
        updatedAt
      }
      relatedCareer {
        id
        name
        short_description
        description
        categoryID
        archive
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createCareerVideo = /* GraphQL */ `
  mutation CreateCareerVideo(
    $input: CreateCareerVideoInput!
    $condition: ModelCareerVideoConditionInput
  ) {
    createCareerVideo(input: $input, condition: $condition) {
      id
      careerID
      name
      description
      fileURL
      career {
        id
        name
        short_description
        description
        categoryID
        archive
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateCareerVideo = /* GraphQL */ `
  mutation UpdateCareerVideo(
    $input: UpdateCareerVideoInput!
    $condition: ModelCareerVideoConditionInput
  ) {
    updateCareerVideo(input: $input, condition: $condition) {
      id
      careerID
      name
      description
      fileURL
      career {
        id
        name
        short_description
        description
        categoryID
        archive
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteCareerVideo = /* GraphQL */ `
  mutation DeleteCareerVideo(
    $input: DeleteCareerVideoInput!
    $condition: ModelCareerVideoConditionInput
  ) {
    deleteCareerVideo(input: $input, condition: $condition) {
      id
      careerID
      name
      description
      fileURL
      career {
        id
        name
        short_description
        description
        categoryID
        archive
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createStudent = /* GraphQL */ `
  mutation CreateStudent(
    $input: CreateStudentInput!
    $condition: ModelStudentConditionInput
  ) {
    createStudent(input: $input, condition: $condition) {
      id
      userID
      gradeID
      schoolID
      grade {
        id
        name
        status
        createdAt
        updatedAt
      }
      school {
        id
        name
        status
        createdAt
        updatedAt
      }
      sponsors {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateStudent = /* GraphQL */ `
  mutation UpdateStudent(
    $input: UpdateStudentInput!
    $condition: ModelStudentConditionInput
  ) {
    updateStudent(input: $input, condition: $condition) {
      id
      userID
      gradeID
      schoolID
      grade {
        id
        name
        status
        createdAt
        updatedAt
      }
      school {
        id
        name
        status
        createdAt
        updatedAt
      }
      sponsors {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteStudent = /* GraphQL */ `
  mutation DeleteStudent(
    $input: DeleteStudentInput!
    $condition: ModelStudentConditionInput
  ) {
    deleteStudent(input: $input, condition: $condition) {
      id
      userID
      gradeID
      schoolID
      grade {
        id
        name
        status
        createdAt
        updatedAt
      }
      school {
        id
        name
        status
        createdAt
        updatedAt
      }
      sponsors {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createGrade = /* GraphQL */ `
  mutation CreateGrade(
    $input: CreateGradeInput!
    $condition: ModelGradeConditionInput
  ) {
    createGrade(input: $input, condition: $condition) {
      id
      name
      status
      students {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateGrade = /* GraphQL */ `
  mutation UpdateGrade(
    $input: UpdateGradeInput!
    $condition: ModelGradeConditionInput
  ) {
    updateGrade(input: $input, condition: $condition) {
      id
      name
      status
      students {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteGrade = /* GraphQL */ `
  mutation DeleteGrade(
    $input: DeleteGradeInput!
    $condition: ModelGradeConditionInput
  ) {
    deleteGrade(input: $input, condition: $condition) {
      id
      name
      status
      students {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createSchool = /* GraphQL */ `
  mutation CreateSchool(
    $input: CreateSchoolInput!
    $condition: ModelSchoolConditionInput
  ) {
    createSchool(input: $input, condition: $condition) {
      id
      name
      status
      students {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateSchool = /* GraphQL */ `
  mutation UpdateSchool(
    $input: UpdateSchoolInput!
    $condition: ModelSchoolConditionInput
  ) {
    updateSchool(input: $input, condition: $condition) {
      id
      name
      status
      students {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteSchool = /* GraphQL */ `
  mutation DeleteSchool(
    $input: DeleteSchoolInput!
    $condition: ModelSchoolConditionInput
  ) {
    deleteSchool(input: $input, condition: $condition) {
      id
      name
      status
      students {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createSponsor = /* GraphQL */ `
  mutation CreateSponsor(
    $input: CreateSponsorInput!
    $condition: ModelSponsorConditionInput
  ) {
    createSponsor(input: $input, condition: $condition) {
      id
      name
      logo
      license_count
      code
      content
      address {
        addressLine1
        addressLine2
        suburb
        city
        province
      }
      contact {
        phoneNumber
        emailAddress
        websiteURL
      }
      students {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateSponsor = /* GraphQL */ `
  mutation UpdateSponsor(
    $input: UpdateSponsorInput!
    $condition: ModelSponsorConditionInput
  ) {
    updateSponsor(input: $input, condition: $condition) {
      id
      name
      logo
      license_count
      code
      content
      address {
        addressLine1
        addressLine2
        suburb
        city
        province
      }
      contact {
        phoneNumber
        emailAddress
        websiteURL
      }
      students {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteSponsor = /* GraphQL */ `
  mutation DeleteSponsor(
    $input: DeleteSponsorInput!
    $condition: ModelSponsorConditionInput
  ) {
    deleteSponsor(input: $input, condition: $condition) {
      id
      name
      logo
      license_count
      code
      content
      address {
        addressLine1
        addressLine2
        suburb
        city
        province
      }
      contact {
        phoneNumber
        emailAddress
        websiteURL
      }
      students {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createSponsorStudentBridge = /* GraphQL */ `
  mutation CreateSponsorStudentBridge(
    $input: CreateSponsorStudentBridgeInput!
    $condition: ModelSponsorStudentBridgeConditionInput
  ) {
    createSponsorStudentBridge(input: $input, condition: $condition) {
      id
      sponsorID
      studentID
      sponsor {
        id
        name
        short_description
        description
        categoryID
        archive
        createdAt
        updatedAt
      }
      student {
        id
        name
        short_description
        description
        categoryID
        archive
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateSponsorStudentBridge = /* GraphQL */ `
  mutation UpdateSponsorStudentBridge(
    $input: UpdateSponsorStudentBridgeInput!
    $condition: ModelSponsorStudentBridgeConditionInput
  ) {
    updateSponsorStudentBridge(input: $input, condition: $condition) {
      id
      sponsorID
      studentID
      sponsor {
        id
        name
        short_description
        description
        categoryID
        archive
        createdAt
        updatedAt
      }
      student {
        id
        name
        short_description
        description
        categoryID
        archive
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteSponsorStudentBridge = /* GraphQL */ `
  mutation DeleteSponsorStudentBridge(
    $input: DeleteSponsorStudentBridgeInput!
    $condition: ModelSponsorStudentBridgeConditionInput
  ) {
    deleteSponsorStudentBridge(input: $input, condition: $condition) {
      id
      sponsorID
      studentID
      sponsor {
        id
        name
        short_description
        description
        categoryID
        archive
        createdAt
        updatedAt
      }
      student {
        id
        name
        short_description
        description
        categoryID
        archive
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
