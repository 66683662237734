import React from 'react';
import {
  TopToolbar, CreateButton, RefreshButton,
  EditButton, List, Datagrid, TextField,
} from 'react-admin';
import { PropTypes } from 'prop-types';

const SchoolActions = ({ basePath }) => (
  <TopToolbar>
    <CreateButton basePath={basePath} />
    <RefreshButton />
  </TopToolbar>
);

SchoolActions.propTypes = {
  basePath: PropTypes.string.isRequired,
};

const SchoolList = (props) => (
  <List
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
    actions={<SchoolActions />}
    bulkActionButtons={false}
  >
    <Datagrid rowClick="edit">
      <TextField source="name" />
      <TextField source="status" />
      <EditButton />
    </Datagrid>
  </List>
);

export default SchoolList;
