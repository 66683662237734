import React from 'react';
import {
  Create, SimpleForm, AutocompleteInput, ReferenceInput,
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';

const CreateQuestion = (props) => (
  <Create
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
    resource="Questions"
  >
    <SimpleForm redirect="list">
      <ReferenceInput label="Category" source="categoryID" resource="categoryy" reference="categories">
        <AutocompleteInput
          optionText="name"
        />
      </ReferenceInput>
      <RichTextInput multiline source="description" />
    </SimpleForm>
  </Create>
);

export default CreateQuestion;
