/* eslint-disable no-param-reassign */
import React, { useCallback } from 'react';
import {
  Create, SimpleForm, TextInput, ReferenceInput, AutocompleteInput, useMutation, useRedirect,
  FileInput, FileField, useNotify,
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import { Storage } from 'aws-amplify';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-extraneous-dependencies
import { connect } from 'react-redux';
import { setUploadProgress, successUploadFile } from '../actions';

const CreateCareerVideo = (props) => {
  const { setUploadProgressDispatch, successUploadFileDispatch } = props;
  const [mutate] = useMutation();
  const redirect = useRedirect();
  const notify = useNotify();

  const save = useCallback(
    // eslint-disable-next-line consistent-return
    async (values) => {
      // Transform
      delete values.career;

      const { title } = values.fileURL;
      Storage.put(title, values.fileURL.rawFile, {
        progressCallback(progress) {
          if (progress.loaded === progress.total) {
            notify(`${values.name} video successfully uploaded`, 'success');
            successUploadFileDispatch(title);
          } else {
            const progressPercent = ((progress.loaded / progress.total) * 100).toFixed(1);
            setUploadProgressDispatch(title, progressPercent);
          }
        },
        level: 'public',
      });
      values.fileURL = title;

      try {
        await mutate({
          type: 'create',
          resource: 'CareerVideos',
          payload: { data: values },
        }, { returnPromise: true });
        redirect('/careerVideos');
      } catch (error) {
        if (error.body.errors) {
          return error.body.errors;
        }
      }
    },
    [mutate],
  );

  return (
    <Create
    // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      resource="CareerVideos"
    >
      <SimpleForm save={save}>
        <TextInput source="name" />
        <RichTextInput multiline source="description" />
        <ReferenceInput label="Career" source="careerID" resource="career" reference="careers">
          <AutocompleteInput
            optionText="name"
          />
        </ReferenceInput>
        <FileInput source="fileURL" label="Career Video" accept="video/*">
          <FileField source="src" title="title" />
        </FileInput>
      </SimpleForm>
    </Create>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  setUploadProgressDispatch: (id, progress) => dispatch(setUploadProgress(id, progress)),
  successUploadFileDispatch: (id) => dispatch(successUploadFile(id)),
});

CreateCareerVideo.propTypes = {
  id: PropTypes.string.isRequired,
  setUploadProgressDispatch: PropTypes.func.isRequired,
  successUploadFileDispatch: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateCareerVideo);
