/* eslint-disable no-param-reassign */
import React, { useCallback, useState, useEffect } from 'react';
import {
  Edit, SimpleForm, TextInput, ImageInput, ImageField, NumberInput, useMutation, useRedirect,
  FormDataConsumer, Labeled,
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import { Storage, API } from 'aws-amplify';
import PropTypes from 'prop-types';
import { getSponsor } from '../graphql/queries';

const EditSponsor = (props) => {
  const [mutate] = useMutation();
  const redirect = useRedirect();

  const save = useCallback(
    // eslint-disable-next-line consistent-return
    async (values) => {
      // Transform
      delete values.students;

      if (values.logo.src) {
        // Save new sponsor logo to S3
        const { title } = values.logo;
        await Storage.put(title, values.logo.rawFile, { level: 'public' });
        values.logo = title;
      }

      try {
        await mutate({
          type: 'update',
          resource: 'Sponsors',
          payload: { data: values },
        }, { returnPromise: true });
        redirect('/sponsors');
      } catch (error) {
        if (error.body.errors) {
          return error.body.errors;
        }
      }
    },
    [mutate],
  );

  const [currentLogoSrc, setCurrentLogoSrc] = useState('');

  useEffect(async () => {
    const category = await API.graphql({ query: getSponsor, variables: { id: props.id } });
    // eslint-disable-next-line no-prototype-builtins
    const src = category.data.getSponsor.hasOwnProperty('logo') ? await Storage.get(category.data.getSponsor.logo) : '';
    setCurrentLogoSrc(src);
  }, []);

  return (
    <Edit
    // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      title="Edit sponsor"
      transform={(data) => {
        /**
       * Remove keys for relations from the object because
       * react-admin devs are not good at graphql
       * and seem to build up the schema for this
       * object based on the get query which includes relations.
       */
        // eslint-disable-next-line no-param-reassign
        delete data.students;
        return data;
      }}
    >
      <SimpleForm save={save}>
        <TextInput source="name" />
        <ImageInput source="logo" label="Logo" accept="image/*">
          <ImageField source="src" title="title" />
        </ImageInput>

        <FormDataConsumer>
          {({ data }) => {
            if (!data?.logo?.src) {
              return (
                <div>
                  <Labeled label="Current logo">
                    <img alt="Current logo" src={currentLogoSrc} style={{ width: '30%' }} />
                  </Labeled>
                </div>
              );
            } return null;
          }}
        </FormDataConsumer>
        <NumberInput source="license_count" min={0} step={1} initialValue={0} />
        <TextInput source="code" />
        <RichTextInput multiline source="content" />
        <TextInput source="address.addressLine1" label="Address Line 1" />
        <TextInput source="address.addressLine2" label="Address Line 2" />
        <TextInput source="address.suburb" label="Suburb" />
        <TextInput source="address.city" label="City" />
        <TextInput source="address.province" label="Province" />
        <TextInput source="contact.phoneNumber" label="Contact Phone Number" />
        <TextInput source="contact.emailAddress" label="Contact Email Address" />
        <TextInput source="contact.websiteURL" label="Website URL" />
      </SimpleForm>
    </Edit>
  );
};

EditSponsor.propTypes = {
  id: PropTypes.string.isRequired,
};

export default EditSponsor;
