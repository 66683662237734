// in App.js
import { Amplify } from '@aws-amplify/core';
import React from 'react';
import {
  Resource,
  // ListGuesser,
  // EditGuesser,
} from 'react-admin';
import {
  AmplifyAdmin,
  CognitoGroupList,
  CognitoUserList,
  // CognitoUserShow,
} from 'react-admin-amplify';
import awsExports from './aws-exports';
import * as mutations from './graphql/mutations';
import * as queries from './graphql/queries';
import * as extendedQueries from './graphql/extended/queries';
import * as extendedMutations from './graphql/extended/mutations';
// import Sandbox from './Sandbox';
import { CategoryList, CategoryCreate, CategoryEdit } from './Categories';
import { CareerList, CareerCreate, CareerEdit } from './Careers';
import { VideoList, VideoCreate, VideoEdit } from './CareerVideos';
import { SchoolList, SchoolCreate, SchoolEdit } from './Schools';
import { GradeList, GradeCreate, GradeEdit } from './Grades';
import { SponsorList, SponsorCreate, SponsorEdit } from './Sponsors';
import { QuestionList, QuestionCreate, QuestionEdit } from './Questions';
import CognitoUserShowCustom from './Users/CognitoUserShowCustom';
import Login from './Authentication/Login';
import uploadProgressReducer from './uploadProgressReducer';
import UploadProgress from './UploadProgress/UploadProgress';

Amplify.configure(awsExports); // Configure Amplify the usual way

function App() {
  return (
    <AmplifyAdmin // Replace the Admin component of react-admin
      loginPage={Login}
      operations={{
        queries: {
          ...queries,
          ...extendedQueries,
        },
        // queries,
        mutations: {
          ...mutations,
          ...extendedMutations,
        },
      }} // Pass the queries and mutations
      options={{
        authGroups: ['admin'],
        enableAdminQueries: true,
      }}
      customReducers={{ fileProgress: uploadProgressReducer }}
    >
      <Resource name="careers" list={CareerList} create={CareerCreate} edit={CareerEdit} />
      <Resource name="categories" list={CategoryList} create={CategoryCreate} edit={CategoryEdit} />
      {/* shim to make requests work. */}
      {/* <Resource name="categoryy" /> */}
      {/* <Resource name="MyCustomPage" list={Sandbox} /> */}
      {/* Set the resources as you would do within Admin component */}
      <Resource
        name="cognitoUsers"
        options={{ label: 'Users' }}
        list={CognitoUserList}
        show={CognitoUserShowCustom}
      />
      <Resource
        name="cognitoGroups"
        options={{ label: 'User Groups' }}
        list={CognitoGroupList}
      />
      <Resource
        name="careerVideos"
        options={{ label: 'Career Videos' }}
        list={VideoList}
        create={VideoCreate}
        edit={VideoEdit}
      />
      <Resource
        name="schools"
        list={SchoolList}
        create={SchoolCreate}
        edit={SchoolEdit}
      />
      <Resource
        name="grades"
        list={GradeList}
        create={GradeCreate}
        edit={GradeEdit}
      />
      <Resource
        name="sponsors"
        list={SponsorList}
        create={SponsorCreate}
        edit={SponsorEdit}
      />
      <Resource
        name="questions"
        list={QuestionList}
        create={QuestionCreate}
        edit={QuestionEdit}
      />
      <UploadProgress />
    </AmplifyAdmin>
  );
}

export default App;
