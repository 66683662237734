import React from 'react';
import {
  Edit, SimpleForm, TextInput, ReferenceInput, SelectInput, BooleanInput,
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';

const EditCareer = (props) => (
  <Edit
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
    // resource="careery"
    title="Edit career"
    transform={(data) => {
      /**
       * Remove keys for relations from the object because
       * react-admin devs are not good at graphql
       * and seem to build up the schema for this
       * object based on the get query which includes relations.
       */
      // eslint-disable-next-line no-param-reassign
      delete data.category;
      // eslint-disable-next-line no-param-reassign
      delete data.relatedCareers;
      // eslint-disable-next-line no-param-reassign
      delete data.videos;
      return data;
    }}
  >
    <SimpleForm>
      <TextInput source="name" />
      <ReferenceInput label="Category" source="categoryID" reference="categories">
        <SelectInput optionText="name" />
      </ReferenceInput>
      <TextInput multiline source="short_description" />
      <RichTextInput multiline source="description" />
      <BooleanInput source="archive" />
    </SimpleForm>
  </Edit>
);

export default EditCareer;
