/* eslint-disable no-param-reassign */
import React, { useCallback } from 'react';
import {
  Create, SimpleForm, TextInput, ImageInput, NumberInput, useMutation, ImageField, useRedirect,
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import { Storage } from 'aws-amplify';

const CreateSponsor = (props) => {
  const [mutate] = useMutation();
  const redirect = useRedirect();

  const save = useCallback(
    // eslint-disable-next-line consistent-return
    async (values) => {
      // Transform
      delete values.students;

      const { title } = values.logo;
      await Storage.put(title, values.logo.rawFile, { level: 'public' });
      values.logo = title;

      try {
        await mutate({
          type: 'create',
          resource: 'Sponsors',
          payload: { data: values },
        }, { returnPromise: true });
        redirect('/sponsors');
      } catch (error) {
        if (error.body.errors) {
          return error.body.errors;
        }
      }
    },
    [mutate],
  );

  return (
    <Create
    // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      resource="Sponsors"
    >
      <SimpleForm save={save}>
        <TextInput source="name" />
        <ImageInput source="logo" label="Logo" accept="image/*">
          <ImageField source="src" title="title" />
        </ImageInput>
        <NumberInput source="license_count" min={0} step={1} initialValue={0} />
        <TextInput source="code" />
        <RichTextInput multiline source="content" />
        <TextInput source="address.addressLine1" label="Address Line 1" />
        <TextInput source="address.addressLine2" label="Address Line 2" />
        <TextInput source="address.suburb" label="Suburb" />
        <TextInput source="address.city" label="City" />
        <TextInput source="address.province" label="Province" />
        <TextInput source="contact.phoneNumber" label="Contact Phone Number" />
        <TextInput source="contact.emailAddress" label="Contact Email Address" />
        <TextInput source="contact.websiteURL" label="Website URL" />
      </SimpleForm>
    </Create>
  );
};

export default CreateSponsor;
