/* eslint-disable no-param-reassign */
import React, { useCallback } from 'react';
import {
  Create, SimpleForm, TextInput, ImageInput, ImageField, useMutation, useRedirect,
} from 'react-admin';
import { Storage } from 'aws-amplify';

const CreateCategory = (props) => {
  const [mutate] = useMutation();
  const redirect = useRedirect();

  const save = useCallback(
    // eslint-disable-next-line consistent-return
    async (values) => {
      // Transform
      delete values.careers;
      delete values.questions;

      const { title } = values.icon;
      await Storage.put(title, values.icon.rawFile, { level: 'public' });
      values.icon = title;

      try {
        await mutate({
          type: 'create',
          resource: 'Categories',
          payload: { data: values },
        }, { returnPromise: true });
        redirect('/categories');
      } catch (error) {
        if (error.body.errors) {
          return error.body.errors;
        }
      }
    },
    [mutate],
  );

  return (
    <Create
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      title="Create a new category"
    >
      <SimpleForm save={save}>
        <TextInput source="name" />
        <TextInput source="colour" />
        <ImageInput source="icon" label="Icon" accept="image/*">
          <ImageField source="src" title="title" />
        </ImageInput>
      </SimpleForm>
    </Create>
  );
};

export default CreateCategory;
