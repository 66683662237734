import React from 'react';
import {
  TopToolbar, CreateButton, RefreshButton,
  EditButton, List, Datagrid, TextField,
} from 'react-admin';
import { PropTypes } from 'prop-types';

const SponsorActions = ({ basePath }) => (
  <TopToolbar>
    <CreateButton basePath={basePath} />
    <RefreshButton />
  </TopToolbar>
);

SponsorActions.propTypes = {
  basePath: PropTypes.string.isRequired,
};

const SponsorList = (props) => (
  <List
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
    actions={<SponsorActions />}
    bulkActionButtons={false}
  >
    <Datagrid rowClick="edit">
      <TextField source="name" />
      <TextField source="license_count" label="License Count" />
      <TextField source="contact.emailAddress" label="Contact" />
      <TextField source="contact.websiteURL" label="Website" />
      <EditButton />
    </Datagrid>
  </List>
);

export default SponsorList;
