/* eslint-disable no-param-reassign */
import React from 'react';
import {
  Edit, SimpleForm, ReferenceInput, SelectInput,
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';

const EditQuestion = (props) => (
  <Edit
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
    title="Edit question"
    transform={(data) => {
      /**
       * Remove keys for relations from the object because
       * react-admin devs are not good at graphql
       * and seem to build up the schema for this
       * object based on the get query which includes relations.
       */
      delete data.category;
      return data;
    }}
  >
    <SimpleForm>
      <ReferenceInput label="Category" source="categoryID" reference="categories">
        <SelectInput optionText="name" />
      </ReferenceInput>
      <RichTextInput multiline source="description" />
    </SimpleForm>
  </Edit>
);

export default EditQuestion;
