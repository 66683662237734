import React from 'react';
import {
  BooleanField,
  ChipField,
  DateField,
  ReferenceManyField,
  Show,
  SimpleShowLayout,
  SingleFieldList,
  TextField,
  useShowController,
  Button,
} from 'react-admin';
import { Auth, API } from 'aws-amplify';
import PropTypes from 'prop-types';

const CognitoUserShowTitle = ({ record }) => (
  <span>
    Cognito User
    {record ? `#${record.id}` : ''}
  </span>
);

CognitoUserShowTitle.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  record: PropTypes.object,
};

CognitoUserShowTitle.defaultProps = {
  record: {},
};

export default function CognitoUserShowCustom(props) {
  const { record } = useShowController(props);

  if (!record) {
    return null;
  }

  const {
    id,
    Enabled,
    UserStatus,
    UserLastModifiedDate,
    UserCreateDate,
    ...attributes
  } = record;

  const makeAdmin = async () => {
    const apiName = 'AdminQueries';
    const path = '/addUserToGroup';
    const myInit = {
      body: {
        username: record.id,
        groupname: 'admin',
      },
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
      },
    };
    return API.post(apiName, path, myInit);
  };

  const confirmUser = async () => {
    const apiName = 'AdminQueries';
    const path = '/confirmUserSignUp';
    const myInit = {
      body: {
        username: record.id,
      },
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
      },
    };
    return API.post(apiName, path, myInit);
  };

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Show title={<CognitoUserShowTitle />} {...props}>
      <SimpleShowLayout>
        <TextField source="id" label="Username" />
        <ReferenceManyField
          reference="cognitoGroups"
          target="listGroupsForUser.username"
          label="Groups"
        >
          <SingleFieldList linkType={false}>
            <ChipField source="id" />
          </SingleFieldList>
        </ReferenceManyField>
        <Button onClick={makeAdmin} label="Make admin" />
        <Button onClick={confirmUser} label="Confirm user" />
        <BooleanField source="Enabled" />
        <TextField source="UserStatus" />
        {Object.keys(attributes).map((attribute) => (
          <TextField key={attribute} record={record} source={attribute} />
        ))}
        <DateField source="UserLastModifiedDate" />
        <DateField source="UserCreateDate" />
      </SimpleShowLayout>
    </Show>
  );
}
