/* eslint-disable no-param-reassign */
import React, { useCallback, useState, useEffect } from 'react';
import {
  Edit, SimpleForm, TextInput, ReferenceInput, AutocompleteInput, useMutation, useRedirect,
  FileInput, FileField, FormDataConsumer, Labeled, useNotify,
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import { Storage, API } from 'aws-amplify';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-extraneous-dependencies
import { connect } from 'react-redux';
import { getCareerVideo } from '../graphql/queries';
import { setUploadProgress, successUploadFile } from '../actions';

const EditCareerVideos = (props) => {
  const { setUploadProgressDispatch, successUploadFileDispatch } = props;
  const [mutate] = useMutation();
  const redirect = useRedirect();
  const notify = useNotify();

  const [currentVideoTitle, setCurrentVideoTitle] = useState('');

  const save = useCallback(
    // eslint-disable-next-line consistent-return
    async (values) => {
      // Transform
      delete values.career;

      if (values?.fileURL?.src) {
        // Save new career video to S3
        const { title } = values.fileURL;
        Storage.put(title, values.fileURL.rawFile, {
          progressCallback(progress) {
            if (progress.loaded === progress.total) {
              notify(`${values.name} video successfully uploaded`, 'success');
              successUploadFileDispatch(title);
            } else {
              const progressPercent = ((progress.loaded / progress.total) * 100).toFixed(1);
              setUploadProgressDispatch(title, progressPercent);
            }
          },
          level: 'public',
        });
        values.fileURL = title;
      } else {
        values.fileURL = currentVideoTitle;
      }

      try {
        await mutate({
          type: 'update',
          resource: 'CareerVideos',
          payload: { data: values },
        }, { returnPromise: true });
        redirect('/careerVideos');
      } catch (error) {
        if (error.body.errors) {
          return error.body.errors;
        }
      }
    },
    [mutate, currentVideoTitle],
  );

  useEffect(async () => {
    const careerVideo = await API.graphql({ query: getCareerVideo, variables: { id: props.id } });
    const src = careerVideo.data.getCareerVideo.fileURL;
    setCurrentVideoTitle(src);
  }, []);

  return (
    <Edit
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      title="Edit video"
    >
      <SimpleForm save={save}>
        <TextInput source="name" />
        <RichTextInput multiline source="description" />
        <ReferenceInput label="Career" source="careerID" resource="career" reference="careers">
          <AutocompleteInput
            optionText="name"
          />
        </ReferenceInput>
        <FileInput source="fileURL" label="Career Video" accept="video/*">
          <FileField source="src" title="title" />
        </FileInput>
        <FormDataConsumer>
          {({ data }) => {
            if (!data?.fileURL?.src) {
              return (
                <div>
                  <Labeled label="Current career video">
                    <p>{currentVideoTitle}</p>
                  </Labeled>
                </div>
              );
            } return null;
          }}
        </FormDataConsumer>
      </SimpleForm>
    </Edit>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  setUploadProgressDispatch: (id, progress) => dispatch(setUploadProgress(id, progress)),
  successUploadFileDispatch: (id) => dispatch(successUploadFile(id)),
});

EditCareerVideos.propTypes = {
  id: PropTypes.string.isRequired,
  setUploadProgressDispatch: PropTypes.func.isRequired,
  successUploadFileDispatch: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditCareerVideos);
