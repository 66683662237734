/**
 * This file houses queries we add for accomodating shortcomines of react-admin-amplify.
 */

/**
 * Had to add this for Category edit to work.
 */
export const updateCategorie = /* GraphQL */ `
 mutation UpdateCategorie(
   $input: UpdateCategoryInput!
   $condition: ModelCategoryConditionInput
 ) {
   updateCategorie: updateCategory(input: $input, condition: $condition) {
     id
     name
     colour
     icon
     updatedAt
   }
 }
`;

export const createCategorie = /* GraphQL */ `
  mutation CreateCategorie(
    $input: CreateCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    createCategorie: createCategory(input: $input, condition: $condition) {
      id
      name
      colour
      icon
      createdAt
      updatedAt
    }
  }
`;

export const deleteCategorie = /* GraphQL */ `
  mutation DeleteCategorie(
    $input: DeleteCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    deleteCategorie: deleteCategory(input: $input, condition: $condition) {
      id
      name
      colour
      icon
      careers {
        nextToken
      }
      questions {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
