/**
 * This file houses queries we add for accomodating shortcomines of react-admin-amplify.
 */

/**
 * Had to add this for Category edit to work.
 */
// eslint-disable-next-line import/prefer-default-export
export const getCategorie = /* GraphQL */ `
  query GetCategorie($id: ID!) {
    getCategorie: getCategory(id: $id) {
      id
      name
      colour
      icon
      careers {
        nextToken
      }
      questions {
        nextToken
      }
      icon
      createdAt
      updatedAt
    }
  }
`;
export const listCareers = /* GraphQL */ `
  query ListCareers(
    $filter: ModelCareerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCareers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        short_description
        description
        categoryID
        archive
        createdAt
        updatedAt
        category {
          id
          name
          colour
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const listCareerVideos = /* GraphQL */ `
  query ListCareerVideos(
    $filter: ModelCareerVideoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCareerVideos(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        careerID
        name
        description
        fileURL
        createdAt
        updatedAt
        career {
          name
        }
      }
      nextToken
    }
  }
`;
export const listSponsors = /* GraphQL */ `
  query ListSponsors(
    $filter: ModelSponsorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSponsors(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        logo
        license_count
        code
        content
        createdAt
        updatedAt
        contact{
          emailAddress
          websiteURL
        }
      }
      nextToken
    }
  }
`;

export const listQuestions = /* GraphQL */ `
  query ListQuestions(
    $filter: ModelQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuestions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        description
        categoryID
        createdAt
        updatedAt
        category {
          id
          name
          colour
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
