/* eslint-disable no-param-reassign */
import React, { useCallback, useEffect, useState } from 'react';
import {
  Edit, SimpleForm, TextInput, ImageInput, ImageField, useMutation, FormDataConsumer, Labeled,
  useRedirect,
} from 'react-admin';
import { Storage, API } from 'aws-amplify';
import PropTypes from 'prop-types';
import { getCategorie } from '../graphql/extended/queries';

const EditCategory = (props) => {
  const [mutate] = useMutation();
  const redirect = useRedirect();

  const save = useCallback(
    // eslint-disable-next-line consistent-return
    async (values) => {
      // Transform
      delete values.careers;
      delete values.questions;

      if (values.icon.src) {
        // Save new category image to S3
        const { title } = values.icon;
        await Storage.put(title, values.icon.rawFile, { level: 'public' });
        values.icon = title;
      }

      try {
        await mutate({
          type: 'update',
          resource: 'Categories',
          payload: { data: values },
        }, { returnPromise: true });
        redirect('/categories');
      } catch (error) {
        if (error.body.errors) {
          return error.body.errors;
        }
      }
    },
    [mutate],
  );

  const [currentIconSrc, setCurrentIconSrc] = useState('');

  useEffect(async () => {
    const category = await API.graphql({ query: getCategorie, variables: { id: props.id } });
    // eslint-disable-next-line no-prototype-builtins
    const src = category.data.getCategorie.hasOwnProperty('icon') ? await Storage.get(category.data.getCategorie.icon) : '';
    setCurrentIconSrc(src);
  }, []);

  return (
    <Edit
    // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <SimpleForm save={save}>
        <TextInput source="name" />
        <TextInput source="colour" />
        <ImageInput source="icon" label="Icon" accept="image/*">
          <ImageField source="src" title="title" />
        </ImageInput>

        <FormDataConsumer>
          {({ data }) => {
            if (!data?.icon?.src) {
              return (
                <div>
                  <Labeled label="Current icon">
                    <img alt="Current category icon" src={currentIconSrc} style={{ width: '30%' }} />
                  </Labeled>
                </div>
              );
            } return null;
          }}
        </FormDataConsumer>
      </SimpleForm>
    </Edit>
  );
};

EditCategory.propTypes = {
  id: PropTypes.string.isRequired,
};

export default EditCategory;
