/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getCategory = /* GraphQL */ `
  query GetCategory($id: ID!) {
    getCategory(id: $id) {
      id
      name
      colour
      icon
      careers {
        nextToken
      }
      questions {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listCategories = /* GraphQL */ `
  query ListCategories(
    $filter: ModelCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCategories(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        colour
        icon
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCareer = /* GraphQL */ `
  query GetCareer($id: ID!) {
    getCareer(id: $id) {
      id
      name
      short_description
      description
      categoryID
      archive
      category {
        id
        name
        colour
        icon
        createdAt
        updatedAt
      }
      relatedCareers {
        nextToken
      }
      videos {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listCareers = /* GraphQL */ `
  query ListCareers(
    $filter: ModelCareerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCareers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        short_description
        description
        categoryID
        archive
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getQuestion = /* GraphQL */ `
  query GetQuestion($id: ID!) {
    getQuestion(id: $id) {
      id
      description
      categoryID
      category {
        id
        name
        colour
        icon
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listQuestions = /* GraphQL */ `
  query ListQuestions(
    $filter: ModelQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuestions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        description
        categoryID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCareerVideo = /* GraphQL */ `
  query GetCareerVideo($id: ID!) {
    getCareerVideo(id: $id) {
      id
      careerID
      name
      description
      fileURL
      career {
        id
        name
        short_description
        description
        categoryID
        archive
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listCareerVideos = /* GraphQL */ `
  query ListCareerVideos(
    $filter: ModelCareerVideoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCareerVideos(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        careerID
        name
        description
        fileURL
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getStudent = /* GraphQL */ `
  query GetStudent($id: ID!) {
    getStudent(id: $id) {
      id
      userID
      gradeID
      schoolID
      grade {
        id
        name
        status
        createdAt
        updatedAt
      }
      school {
        id
        name
        status
        createdAt
        updatedAt
      }
      sponsors {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listStudents = /* GraphQL */ `
  query ListStudents(
    $filter: ModelStudentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStudents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userID
        gradeID
        schoolID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getGrade = /* GraphQL */ `
  query GetGrade($id: ID!) {
    getGrade(id: $id) {
      id
      name
      status
      students {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listGrades = /* GraphQL */ `
  query ListGrades(
    $filter: ModelGradeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGrades(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSchool = /* GraphQL */ `
  query GetSchool($id: ID!) {
    getSchool(id: $id) {
      id
      name
      status
      students {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listSchools = /* GraphQL */ `
  query ListSchools(
    $filter: ModelSchoolFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSchools(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSponsor = /* GraphQL */ `
  query GetSponsor($id: ID!) {
    getSponsor(id: $id) {
      id
      name
      logo
      license_count
      code
      content
      address {
        addressLine1
        addressLine2
        suburb
        city
        province
      }
      contact {
        phoneNumber
        emailAddress
        websiteURL
      }
      students {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listSponsors = /* GraphQL */ `
  query ListSponsors(
    $filter: ModelSponsorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSponsors(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        logo
        license_count
        code
        content
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
